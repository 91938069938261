import { push } from "lib/GoogleTagManager/dataLayer"

export async function pushUserDetails() {
  const userId = window.Config?.current_user_id
  const dataLayerUserId = userId || ""

  push({
    event: "user_details",
    user_id: `${dataLayerUserId}`,
  })
}

/**
 * Pushes a donation event to the data layer when the `window.Subscription` object is present.
 */
export async function pushCompleteDonation() {
  if (!window.Subscription) {
    return
  }

  const subscription = window.Subscription
  const userId = window.Config?.current_user_id

  const dataLayerUserId = userId || ""
  const dataLayerAutoRenewal = subscription.frequency === "monthly"

  push({
    event: "product_sub",
    user_id: `${dataLayerUserId}`,
    product_name: "donation",
    sub_auto_renewal: dataLayerAutoRenewal,
  })
}

export async function pushPurchase() {
  if (!window.GTM?.Purchase) {
    return
  }

  // It is recommended that we clear the `ecommerce` object prior to pushing an ecommerce event
  // to the dataLayer.
  // @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
  push({ ecommerce: null })
  push({
    event: "purchase",
    ecommerce: window.GTM.Purchase,
  })
}

pushUserDetails()
pushCompleteDonation()
pushPurchase()
